import React from 'react'
import styled from 'styled-components'
//import { ThemeContext } from '../context'
import Section from './Section'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const fixed_css = (fixed) => {
    if(fixed){
      return `
        overflow: hidden;
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index:10000;
      `
    } else {
      return `
        z-index:10000;
      `
    }
  }
//console.log(props.data.style)
  const Footer = styled.div`
    ${fixed_css(props.data.fixed)}

    text-align: center;
    margin: 0;
    padding: 0;
`

  return (
    <Footer style={props.data.style}>
      <Section data={props.data.section}/>
    </Footer>
  )

}

export default Component
