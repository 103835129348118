import React from 'react'
import styled from 'styled-components';

//import { ThemeContext } from '../context'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)
  //${themeContext.css[props.data.theme]}
  //  ${props.data.style}
  

  const A = styled.a`
    text-decoration: none;
    a:link {
      text-decoration: none;
    }
    a:visited {
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
    }
    a:active {
      text-decoration: none;
    }
  `
  return (
    <A target='_blank' href={props.data.link_to}>{props.children}</A>
  )
}

export default Component
