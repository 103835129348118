import React from 'react'
import styled from 'styled-components';

//import { ThemeContext } from '../context'
import Img from './Img'
import P from './P'
const debug = false

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const Container = styled.div`
    ${ debug ? 'border: 1px green solid;' : '' }
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  `

  const ItemBox = styled.div`
    ${ debug ? 'border: 1px red solid;' : '' }
    margin: 10px;
    padding: 50px;
    max-width: 400px;
    max-height: 400px;
    text-align: center;
  `

  const Items = ()=>( 

    props.data.items.map((item, index)=>{
      console.log(item)
      const Elements = item.elements ? 
        item.elements.map((element, element_idx)=>{
          switch(element.type){
            case 'P': 
              return <P key={element_idx} data={element}/>
            default:
              return <div key={element_idx} data={element}>{`unknown element type (${element.type})`}</div>
          }
        })
        :
        <div></div>
        
      return(
        <ItemBox key={index}>
          <Img data={{...item.img}} style={{margin: 0, padding: 0}} />
            {item.elements ? 
              <div style={{backgroundColor: 'white', padding: 40}}>
                {Elements}
              </div>
              :
              <div></div>
            }
        </ItemBox>
      )
    })
  )
  
  return (
    <Container>
      <Items/>
    </Container>
  )
}

export default Component
