import React, {useContext} from 'react'
import styled from 'styled-components'

import { ThemeContext } from '../context'
import P from './P'
import BoxesList from './BoxesList'
import Video from './Video'
import SectionVideo from './SectionVideo'

const Component = (props) => {
  const themeContext = useContext(ThemeContext)
  
  const Section = styled.section`
    ${themeContext.css[props.data.theme]}
    ${props.data.style}
  `
  const Elements = props.data.elements.map((element, element_idx)=>{
    switch(element.type){
      case 'P': 
        return <P key={element_idx} data={element}/>
      case 'BoxesList':
        return <BoxesList key={element_idx} data={element}/>
      case 'Video':
        return <Video key={element_idx} data={element}/>
      default:
        return <div key={element_idx} data={element}>{`unknown element type (${element.type})`}</div>
    }
  })

  if(props.data.background && props.data.background.type==='video'){
    return (
        <div id={props.data.menu_title} style={{ padding: 0, margin: 0}}>
          <SectionVideo/>
        </div>
      )
  } else {
    return (
      <Section id={props.data.menu_title}>
        {Elements}
      </Section>                                                              
    )
  }
}

export default Component
