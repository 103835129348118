import React from 'react'

import Site from './parts/Site'
import data_local from './data_local'
//import useFetchData from './useFetchData'

const App = (props) => {

  //const data_api = useFetchData('https://tz8fjl2zse.execute-api.us-east-1.amazonaws.com/dev/data?aa=a2')
  
  return (
    <div>
      <Site data={data_local} />
      {/*<p>{data_api ? data_api.data.host : 'loading...'}</p>*/}
    </div>
  );
}
// href=//www.wix.com/favicon.ico

export default App
