import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-scroll'

const Component = (props) => {

  const Container = styled.div`
    display: flex;
    padding-right: 50px;
    padding-left: 50px;
  `
  //${themeContext.css[props.data.theme]}
  //{props.data.sections.length}

  let menu_items = props.data.map((item, index)=>{
    const Wrap = styled.div`
      padding-right: 5px;
      padding-left: 5px;
    `
    const P = styled.p`
      font: normal normal normal 14px/1.79em raleway,sans-serif;
      cursor: pointer;
      color: #241C24;
    ` 
    return (
      <Wrap key={index}>
        <Link to={item.menu_title} spy={true} smooth={true} offset={0} duration={500} delay={10}>
          <P>{item.menu_title}</P>
        </Link>
      </Wrap>
      )
    // <Wrap><A href={`#${item.menu_title}`}><P>{item.menu_title}</P></A></Wrap> 
  })
  
  return (
    <Container>{menu_items}</Container>
  )
}

export default Component
