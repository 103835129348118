import React from 'react'
import styled from 'styled-components'
//import { ThemeContext } from '../context'
import Img from './Img'
import Menu from './Menu'

const Component = (props) => {
  //const themeContext = useContext(ThemeContext)

  const fixed_css = (fixed) => {
    if(fixed){
      return `
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
      `
    } else {
      return `
      `
    }
  }

  const Header = styled.div`
    ${fixed_css(props.data.fixed)}

    background-color: rgba(240, 240, 240, 1);

    z-index:10000;
    display: flex;
    justify-content: space-between;
  `
  return (
    <Header>
      <Img data={{ ...props.data.left_img }} />
      <Menu data={props.menu} />
    </Header>
  )
}

export default Component
