import React, {useContext} from 'react'
import styled from 'styled-components'

import { ThemeContext } from '../context'

const Component = (props) => {
  const themeContext = useContext(ThemeContext)
  const alt = props.data.alt || 'not defined'
  let width = props.data.width ? `width: ${props.data.width};` : ``
  let height = props.data.height ? `height: ${props.data.height};` : ``
  if(props.data.both){
    width  = `width: ${props.data.both};`
    height = `height: ${props.data.both};`
  }

  const Img = styled.img`
    ${themeContext.css[props.data.theme]};
    ${width}
    ${height}
  `
  return (
    <Img alt={alt} src={props.data.src} style={props.data.style} />
  )
}

export default Component
