import React from 'react'

export const ThemeContext = React.createContext({
  css: `
  `,
})

/*
const localImageSize = 40
export const LocalContext = React.createContext(
  <img 
    src='http://aux.iconspalace.com/uploads/2025710045.png' 
    style={{ width: localImageSize, height: localImageSize }}
    alt='local flag'
  />
)
*/

// Context.Consumer, Context.Provider

