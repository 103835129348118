
const show_emaxer = true

const emaxer = {
  site: {
    title: `Emaxer`,
    favicon: `https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/favicon/favicon-emaxer.ico`
  },
  header: {
    fixed: true,
    left_img: {
      src: `https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/emaxer.png`,
      alt: 'Emaxer',
      width: '156px',
      height: '60px',
    },
  },
  footer: {
    fixed: false,
    section: {
      menu_title: `Welcome`,
      elements: [{
        type: 'P', theme: `header_detail`, style: { color: 'white', fontSize: 12 }, text: `Emaxer Limited: England and Wales under Company No. 07927941`
      }, {
        type: 'P', theme: `header_detail`, style: { color: 'white', fontSize: 12 }, text: `TAB Est.: Saudi Arabia under Company No. 4030080018, since 1992`
      }, {
        type: 'P', theme: `header_detail`, style: { color: 'white', fontSize: 12 }, text: `Emaxer ©`
      }],
      theme: `footer`,
      style: { backgroundColor: '#333' },
    },
  },
  sections: [

    {

      menu_title: `Home`,
      background: {
        type: 'video', browser_support_err: 'Your browser does not support the video tag.', sources: [
          { type: 'mp4', src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/emaxer-welcome.mp4' },
        ],
      },
      theme: 'section_dark_bg',
      elements: [{
        type: 'P', theme: `header_title`, text: `Welcome to Emaxer`, style: `
        
        font-weight: bold;

        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        vertical-align: baseline;
        background: 0 0;

        color: #f48c34;

        line-height: 1.25em;
        letter-spacing: normal;
        pointer-events: auto;
        font: normal normal normal 72px/1.25em 'playfair display',serif;
        word-wrap: break-word;

        text-align: left;
        padding-left: 15%;
        padding-right: 15%;   
        `
      }, {
        type: 'P', theme: `header_sub`, text: `We focus on your IT, so you can focus on your Business`, style: `
        
        color: #241c24;

        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        vertical-align: baseline;
        background: 0 0;

        text-align: center;
        line-height: 1.35em;

        letter-spacing: normal;

        pointer-events: auto;

        font: normal normal normal 40px/1.35em 'playfair display',serif;

        color: #fff;
        text-align: left;
        padding-left: 15%;
        padding-right: 15%;
        margin-top: 35px;
        `
      }, {
        type: 'P', theme: `header_detail`, text: `Emaxer will provide you with practical, reliable and cost-effective solutions for all your IT needs, utilizing the latest stable and proven technologies, driven by professionals and consultants.`, style: `
        font-size: 16px;

        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        vertical-align: baseline;
        background: 0 0;

        font-weight: 300;
        
        font-family: helveticaneuew01-55roma,helveticaneuew02-55roma,helveticaneuew10-55roma,helvetica neue,helvetica,arial,meiryo,hiragino kaku gothic pro w3,hiragino kaku gothic pro,sans-serif;
        
        line-height: 1.75em;
        text-align: center;

        letter-spacing: normal;

        pointer-events: auto;

        color: #fff;
        text-align: left;
        padding-left: 15%;
        padding-right: 15%;
        margin-top: 35px;
        padding-bottom: 20px;
      `,
      }],

    }, {

      menu_title: `Partners`,
      elements: [{
        type: 'P', theme: `header_title`, text: `Our Partnerships`
      }, {
        type: 'P', theme: `header_detail`, text: `Emaxer ensures utmost quality services by partnering with strategic vendors`
      }, {
        type: 'BoxesList', items: [
          {
            img: {
              alt: 'Microsoft',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/microsoft-partership.jpg',
            },
          }, {
            img: {
              alt: 'Oracle',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/oracle-partership.gif',
            }
          }, {
            img: {
              alt: 'Cisco',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/cisco-partership.gif',
            }
          }, {
            img: {
              alt: 'Dell',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/dell-partership.jpg',
            }
          }, {
            img: {
              alt: 'Amazon',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/amazon-partership.jpg',
            }
          }, {
            img: {
              alt: 'HP',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/hp-partership.jpg',
            }
          }, {
            img: {
              alt: 'Intel',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/intel-partnership.png',
            }
          }, {
            img: {
              alt: 'Novell',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/novell-partnership.png',
            }
          }, {
            img: {
              alt: 'Trend Micro',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/trendmicro-partership.jpg',
            }
          }, {
            img: {
              alt: 'Symantec',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/symantec-partership.jpg',
            }
          }, {
            img: {
              alt: 'APC',
              src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/partnerships/apc-partership.jpg',
              style: { width: '120px' },
            }
          },
        ],
      }],
      theme: `section_light_bg`,

    }, {

      menu_title: `Services`,
      elements: [{
        type: 'P', theme: `header_title`, text: `Our Services`
      }, {
        type: 'BoxesList', items: [{
          title: `Business Process`,
          detail: `Documentation, re-engineering, and automation`,
          img: {
            alt: 'Business Process',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/services/business-process.png',
            both: '100%',
          },
          elements:[{
            type: 'P', theme: `header_sub`, text: `Business Process`, style: { color: 'black', backgroundColor: 'white' }
          },{
            type: 'P', theme: `header_detail`, text: `Documentation, re-engineering, and automation.`, style: { color: 'black', backgroundColor: 'white' }
          }],
        }, {
          title: `Software Development`,
          detail: `For the Web, IOS, Android, and SharePoint`,
          img: {
            alt: 'Software Development',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/services/software-development.jpg',
            both: '100%',
          },
          elements:[{
            type: 'P', theme: `header_sub`, text: `Software Development`, style: { color: 'black', backgroundColor: 'white' }
          },{
            type: 'P', theme: `header_detail`, text: `For the Web, IOS, Android, and SharePoint`, style: { color: 'black', backgroundColor: 'white' }
          }],
        }, {
          title: `IT Site Support`,
          detail: `Designing, implementation, supervising, and support for Servers, Networking, Communication, Security, and workstations.`,
          img: {
            alt: 'IT Site Support',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/services/site-support.jpg',
            both: '100%',
          },
          elements:[{
            type: 'P', theme: `header_sub`, text: `IT Site Support`, style: { color: 'black', backgroundColor: 'white' }
          },{
            type: 'P', theme: `header_detail`, text: `Designing, implementation, supervising, and support for Servers, Networking, Communication, Security, and workstations.`, style: { color: 'black', backgroundColor: 'white' }
          }],
        }, {
          title: `Smart School Systems`,
          detail: `Is a system to link students, parents, teachers, and school administration employees`,
          more: {
            text: 'more...',
            url: 'http://noorsch.com/',
          },
          img: {
            alt: 'Smart School Systems',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/services/eschool.png',
            both: '100%',
          },
          elements:[{
            type: 'P', theme: `header_sub`, text: `Business Process`, style: { color: 'black', backgroundColor: 'white' }
          },{
            type: 'P', theme: `header_detail`, text: `Noor E-School System`, style: { color: 'black', backgroundColor: 'white' }
          },{
            type: 'P', theme: `header_detail`, text: `more...`, link_to: `emaxer.com`, style: { color: 'black', backgroundColor: 'white' }
          }],
        }],
      }],
      theme: `section_dark_bg`,

    }, {

      menu_title: `Clients`,
      elements: [{
        type: 'P', theme: `header_title`, text: `Our Clients`
      }, {
        type: 'BoxesList', items: [{
          img: {
            alt: 'Hyundai',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/hyundai.jpg',
          },
        }, {
          img: {
            alt: 'Prometric',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/prometric.jpg',
          },
        }, {
          img: {
            alt: 'Savola',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/savola.jpg',
          },
        }, {
          img: {
            alt: 'Saudi Bio-acids Company',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/bioacids.png',
          },
        }, {
          img: {
            alt: 'Ministry of Education',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/moe.png',
          },
        }, {
          img: {
            alt: 'Umm Al-Qura University',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/uqu.jpg',
          },
        }, {
          img: {
            alt: 'King Abdulaziz University',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/kaau.jpg',
          },
        }, {
          img: {
            alt: 'Talal International School',
            src: 'https://emaxer-websites-resources.s3.amazonaws.com/emaxer.com/clients/talalschool.png',
            style: {
              width: 150,
            },
          },
          width: 10,
          height: 10,
          style: {
            width: 10,
            height: 10,
          }
        }],
      }],
      theme: `section_light_bg`,

    }, {

      menu_title: `Contact`,
      elements: [{
        type: 'P', theme: `header_title`, text: `Contact Us`
      }, {
        type: 'P', theme: `header_detail`, text: `info@emaxer.com`
      }, {
        type: 'P', theme: `header_detail`, text: `open in mail`, link_to: 'mailto:info@emaxer.com'
      }],
      theme: `section_dark_bg`,
    }],

  theme: {
    site: `
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      @import url('https://fonts.googleapis.com/css?family=Playfair+Display&display=swap');
    `,
    section_light_bg: `
      background-color: rgba(255, 255, 255, 1);
      padding: 100px;
    `,
    section_dark_bg: `
      background-color: rgba(244, 140, 52, 1);
      padding: 100px;
    `,
    header_title: `
      color: #241c24;

      margin: 0;
      padding: 0;
      padding-bottom: 30px;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      text-align: center;
      line-height: 1.35em;

      letter-spacing: normal;

      pointer-events: auto;

      font: normal normal normal 40px/1.35em 'playfair display',serif;
    `,
    header_sub: `
      font-weight: bold;

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      font-style: italic;

      line-height: 1.67em;
      letter-spacing: normal;
      pointer-events: auto;

      
      text-align: center;

      font: normal normal normal 20px/1.67em 'playfair display',serif;

    `,
    header_detail: `
      font-size: 16px;

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      font-weight: 300;
      
      font-family: helveticaneuew01-55roma,helveticaneuew02-55roma,helveticaneuew10-55roma,helvetica neue,helvetica,arial,meiryo,hiragino kaku gothic pro w3,hiragino kaku gothic pro,sans-serif;
      
      line-height: 1.75em;
      text-align: center;

      letter-spacing: normal;

      pointer-events: auto;
    `, //       color: #241c24;
    footer: `
      color: #fff;

      background-color: rgba(244, 140, 52, 1);

      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      background: 0 0;

      text-align: center;
      line-height: 1.79em;

      letter-spacing: normal;

      pointer-events: auto;

      font: normal normal normal 14px/1.79em raleway,sans-serif;
    `
  },
}

const tallaschool = {
  title: `Talal School`,
  sections: [{

  }],
}

const data_local = show_emaxer ? emaxer : tallaschool

export default data_local


/*

,{

    menu_title: `Subscribe`,
    elements: [{
    }],
    theme: `section_light_bg`,

  }

*/