import React, {useContext} from 'react'
import {Helmet} from "react-helmet"
import Favicon from 'react-favicon'

import { ThemeContext } from '../context'
import Header from './Header'
import Footer from './Footer'
import Section from './Section'

const Component = (props) => {

  let themeContext = useContext(ThemeContext)
  themeContext.css = props.data.theme

  const sections = props.data.sections.map((section, section_idx)=>{
    return (
      <Section key={section_idx} data={section}/>
    )
  })

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.data.site.title}</title>
      </Helmet>
      <Favicon url= {props.data.site.favicon} />
      <Header data={props.data.header} menu={props.data.sections}/>
      <div style={{height: 50}}/>      
      {sections}
      <Footer data={props.data.footer}/>
    </div>
  )
}

export default Component